import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import Header from '../components/UserHeader'
import { TextField, Button, IconButton, Modal } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import { Line } from 'react-chartjs-2'
import { SalaryService } from '../service/SalaryService'
import { useSnackbar } from '../helpers/SnackbarProvider'
import { checkModalResponse } from '../helpers/helpers'
import { useSelector } from 'react-redux'
import EditOutlined from '@mui/icons-material/EditOutlined'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import CloseIcon from '@mui/icons-material/Close'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import moment from 'moment'
import { AnalyticsService } from '../service/AnalyticsService'
import dayjs from 'dayjs'
import { ru } from 'date-fns/locale/ru'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import { GridCloseIcon } from '@mui/x-data-grid'
import { PaymentHistoryService } from '../service/PaymentHistoryService'

const initalSalaryData = {
    user: {
        id: 0,
        username: 'ruslan',
        full_name: 'Ruslan',
        phone: '996555000555',
        position: {
            id: 4,
            name: 'Помощник бухгалтера',
            slug: 'assistant_accountant',
        },
        date_joined: '2024-10-05',
        balance: 0,
    },
    salary: 0,
    total_salary: 0,
    accountant_percentage: 0,
    tax: 0,
    percentage_amount: 0,
    created_at: '2024-11-01',
    confirm: 'confirm',
    history: false,
}

const servicesModalInitialValues = {
    show: false,
    values: {
        title: '',
    },
    validation: {
        error: {
            title: false,
        },
        message: {
            title: '',
        },
    },
}

const Analytics = () => {
    const [selectedTabs, setSelectedTabs] = useState<Number[]>([1, 2, 3])
    const [allFieldsFilled, setAllFieldsFilled] = useState<boolean>(true)
    const [salaryData, setSalaryData] = useState<any>(initalSalaryData)
    const [servicesModal, setServicesModal] = useState<any>(
        servicesModalInitialValues,
    )
    const { openSnackbar } = useSnackbar()

    const userId = useSelector((state: any) => state.userData.user.id)

    const user = useSelector(
        (state: any) => state.userData?.user?.position?.name,
    )

    // const userDataList = SalaryService.GetSalaryList('')
    //
    // const userData = userDataList?.result?.data?.results?.find(
    //     (item: any) => item.user.id === userId && !item.history,
    // )

    const userSalary = AnalyticsService.GetCurrentUserSalary(userId)
    const servicesList = PaymentHistoryService.GetPaymentHistoryServicesList()

    const filtersInitialValues = {
        date: {
            date_from: dayjs().subtract(2, 'month').format('YYYY-MM'),
            date_to: dayjs().format('YYYY-MM'),
        },
    }

    const formInitialValues = {
        values: {
            id: '',
            salary: '',
            accountant_percentage: '',
            tax: '',
        },
        validation: {
            error: {
                salary: false,
                accountant_percentage: false,
                tax: false,
            },
            message: {
                salary: '',
                accountant_percentage: '',
                tax: '',
            },
        },
        requested: false,
        action: '',
    }

    const [filter, setFilter] = useState<any>(filtersInitialValues)

    const [form, setForm] = useState<any>({
        ...formInitialValues,
        requested: true,
    })

    const handleFormSubmit = (event: React.FormEvent) => {
        event.preventDefault()
        const values = {
            ...form.values,
            user: userId,
            created_at: dayjs().format('YYYY-MM'),
        }

        if (!salaryData) return

        switch (form.action) {
            case 'add':
                if (salaryData?.confirm === 'confirmed') {
                    return
                }
                SalaryService.CreateSalary(values)
                    .then((response) => {
                        setForm(formInitialValues)
                        userSalary.execute()
                        openSnackbar('Оклад успешно добавлен', {
                            variant: 'success',
                        })
                    })
                    .catch((err) => {
                        checkModalResponse(err.response.data, setForm, form)
                        openSnackbar('Не удалось добавить оклад', {
                            variant: 'error',
                        })
                    })

                break

            case 'edit':
                SalaryService.UpdateSalary({ ...values, id: salaryData.id })
                    .then(() => {
                        setForm(formInitialValues)
                        openSnackbar('Оклад успешно изменён', {
                            variant: 'success',
                        })
                        userSalary.execute()
                    })
                    .catch((err) => {
                        checkModalResponse(err.response.data, setForm, form)
                        openSnackbar('Не удалось изменить оклад', {
                            variant: 'error',
                        })
                    })

                break

            default:
                break
        }
    }

    const handleServicesFormSubmit = (event: React.FormEvent) => {
        event.preventDefault()

        PaymentHistoryService.CreatePaymentHistoryService(
            servicesModal.values,
        ).then(() => {
            setServicesModal({
                ...servicesModal,
                values: { ...servicesModal.values, title: '' },
            })
            servicesList.execute()
            openSnackbar('Услуга успешно добавлена', {
                variant: 'success',
            })
        })
    }

    // const analyticsCLients = AnalyticsService.GetAnalyticsClients({
    //   ...filter.date,
    //   ...(filter.date.date_from === null
    //     ? {}
    //     : {
    //         date_from: moment(filter.date.date_from?.$d).format("YYYY-MM"),
    //       }),
    //   ...(filter.date.date_to === null
    //     ? {}
    //     : {
    //         date_to: moment(filter.date.date_to?.$d).format("YYYY-MM"),
    //       }),
    //   user_id: userId,
    // });

    const analyticsCLients = AnalyticsService.GetAnalyticsClients({
        ...filter.date,
        date_from: filter.date.date_from
            ? moment(filter.date.date_from).format('YYYY-MM')
            : null,
        date_to: filter.date.date_to
            ? moment(filter.date.date_to).format('YYYY-MM')
            : null,
        user_id: userId,
    })

    // const analyticsManagerPrecentageSum =
    //   AnalyticsService.GetAnalyticsManagerPrecentageSum({
    //     ...filter.date,
    //     ...(filter.date.date_from === null
    //       ? {}
    //       : {
    //           date_from: moment(filter.date.date_from?.$d).format("YYYY-MM"),
    //         }),
    //     ...(filter.date.date_to === null
    //       ? {}
    //       : {
    //           date_to: moment(filter.date.date_to?.$d).format("YYYY-MM"),
    //         }),
    //     user_id: userId,
    //   });

    const analyticsManagerPrecentageSum =
        AnalyticsService.GetAnalyticsManagerPrecentageSum({
            ...filter.date,
            date_from: filter.date.date_from
                ? moment(filter.date.date_from).format('YYYY-MM')
                : null,
            date_to: filter.date.date_to
                ? moment(filter.date.date_to).format('YYYY-MM')
                : null,
            user_id: userId,
        })

    // const analyticsManagerSalary = AnalyticsService.GetAnalyticsManagerSalary({
    //   ...filter.date,
    //   ...(filter.date.date_from === null
    //     ? {}
    //     : {
    //         date_from: moment(filter.date.date_from?.$d).format("YYYY-MM"),
    //       }),
    //   ...(filter.date.date_to === null
    //     ? {}
    //     : {
    //         date_to: moment(filter.date.date_to?.$d).format("YYYY-MM"),
    //       }),
    //   user_id: userId,
    // });

    const analyticsManagerSalary = AnalyticsService.GetAnalyticsManagerSalary({
        ...filter.date,
        date_from: filter.date.date_from
            ? moment(filter.date.date_from).format('YYYY-MM')
            : null,
        date_to: filter.date.date_to
            ? moment(filter.date.date_to).format('YYYY-MM')
            : null,
        user_id: userId,
    })

    // const analyticsClientsIncome = AnalyticsService.GetClientIncome({
    //   ...filter.date,
    //   ...(filter.date.date_from === null
    //     ? {}
    //     : {
    //         date_from: moment(filter.date.date_from?.$d).format("YYYY-MM"),
    //       }),
    //   ...(filter.date.date_to === null
    //     ? {}
    //     : {
    //         date_to: moment(filter.date.date_to?.$d).format("YYYY-MM"),
    //       }),
    // });

    const analyticsClientsIncome = AnalyticsService.GetClientIncome({
        ...filter.date,
        date_from: filter.date.date_from
            ? moment(filter.date.date_from).format('YYYY-MM')
            : null,
        date_to: filter.date.date_to
            ? moment(filter.date.date_to).format('YYYY-MM')
            : null,
        user_id: userId,
    })

    // const analyticsClientsExpense = AnalyticsService.GetClientExpense({
    //   ...filter.date,
    //   ...(filter.date.date_from === null
    //     ? {}
    //     : {
    //         date_from: moment(filter.date.date_from?.$d).format("YYYY-MM"),
    //       }),
    //   ...(filter.date.date_to === null
    //     ? {}
    //     : {
    //         date_to: moment(filter.date.date_to?.$d).format("YYYY-MM"),
    //       }),
    // });

    const analyticsClientsExpense = AnalyticsService.GetClientExpense({
        ...filter.date,
        date_from: filter.date.date_from
            ? moment(filter.date.date_from).format('YYYY-MM')
            : null,
        date_to: filter.date.date_to
            ? moment(filter.date.date_to).format('YYYY-MM')
            : null,
        user_id: userId,
    })

    // const analyticsClientsNetprofit = AnalyticsService.GetClientNetprofit({
    //   ...filter.date,
    //   ...(filter.date.date_from === null
    //     ? {}
    //     : {
    //         date_from: moment(filter.date.date_from?.$d).format("YYYY-MM"),
    //       }),
    //   ...(filter.date.date_to === null
    //     ? {}
    //     : {
    //         date_to: moment(filter.date.date_to?.$d).format("YYYY-MM"),
    //       }),
    // });

    const analyticsClientsNetprofit = AnalyticsService.GetClientNetprofit({
        ...filter.date,
        date_from: filter.date.date_from
            ? moment(filter.date.date_from).format('YYYY-MM')
            : null,
        date_to: filter.date.date_to
            ? moment(filter.date.date_to).format('YYYY-MM')
            : null,
        user_id: userId,
    })

    // useEffect(() => {
    //     if (userData) {
    //         setForm((prevForm: any) => ({
    //             ...prevForm,
    //             values: {
    //                 ...prevForm.values,
    //                 salary: userData.salary || 0,
    //                 accountant_percentage: userData.accountant_percentage || 0,
    //                 tax: userData.tax || 0,
    //             },
    //         }))
    //     }
    // }, [userData])

    // const [sumManagerPercentageSum, setSumManagerPercentageSum] = useState<
    //     number | null
    // >(null)
    // const [salaryInfo, setSalaryInfo] = useState<any>({
    //     sum: 0,
    //     total: 0
    // })

    useEffect(() => {
        if (!userSalary.loading && !userSalary.error) {
            const resp = userSalary.result?.data
            if (resp.id) {
                setSalaryData(resp)

                setForm((prevForm: any) => ({
                    ...prevForm,
                    values: {
                        ...prevForm.values,
                        salary: resp.salary || 0,
                        accountant_percentage: resp.accountant_percentage || 0,
                        tax: resp.tax || 0,
                    },
                }))

                setAllFieldsFilled(
                    resp?.salary !== undefined &&
                        resp?.accountant_percentage !== undefined &&
                        resp?.tax !== undefined,
                )
            } else {
                setForm((prevForm: any) => ({
                    ...prevForm,
                    action: 'add',
                }))
                setAllFieldsFilled(false)
            }
        }
    }, [userSalary.loading, userSalary.error, userSalary.result])

    const handleTabClick = (tabValue: any) => {
        setSelectedTabs((prevSelectedTabs: any) => {
            if (prevSelectedTabs.includes(tabValue)) {
                return prevSelectedTabs.filter(
                    (value: any) => value !== tabValue,
                )
            } else {
                return [...prevSelectedTabs, tabValue]
            }
        })
    }

    if (userSalary.loading) return <div></div>

    if (
        (user === 'Менеджер' && userSalary.error && !salaryData) ||
        (user === 'Помощник бухгалтера' && userSalary.error && !salaryData)
    ) {
        return <div>Error</div>
    }

    return (
        <div className="w-full">
            <div className="w-full flex justify-between items-end mb-[70px]">
                <div className="w-full flex flex-col gap-[24px] text-[white]">
                    <Header pageTitle={'Главная'} />
                </div>
            </div>

            {user !== 'Администратор' && (
                <div className="py-[40px] px-[30px] border-[1px] border-[#D4D4D4] rounded-[10px]">
                    <div className="flex gap-2 text-[#4768DF]">
                        <span className="font-semibold">Общий баланс:</span>
                        <span>{salaryData?.user?.balance}</span>
                    </div>

                    <h4 className="pb-[30px] mt-[25px] font-bold text-[26px] text-[#282828]">
                        Зарплата
                    </h4>

                    <form
                        onSubmit={handleFormSubmit}
                        className="w-full flex gap-[40px]"
                    >
                        <TextField
                            fullWidth
                            // required
                            label="Оклад"
                            variant="standard"
                            type="text"
                            disabled={allFieldsFilled && form.action !== 'edit'}
                            value={form.values.salary}
                            error={form.validation.error.salary}
                            helperText={form.validation.message.salary}
                            onChange={(e) => {
                                const value = e.target.value
                                setForm((prevForm: any) => ({
                                    ...prevForm,
                                    values: {
                                        ...prevForm.values,
                                        salary: value,
                                    },
                                }))
                            }}
                        />

                        <TextField
                            fullWidth
                            // required
                            label="Сумма бухгалтера"
                            variant="standard"
                            disabled={allFieldsFilled && form.action !== 'edit'}
                            type="text"
                            value={form.values.accountant_percentage}
                            error={form.validation.error.accountant_percentage}
                            helperText={
                                form.validation.message.accountant_percentage
                            }
                            onChange={(e) => {
                                const value = e.target.value
                                setForm((prevForm: any) => ({
                                    ...prevForm,
                                    values: {
                                        ...prevForm.values,
                                        accountant_percentage: value,
                                    },
                                }))
                            }}
                        />

                        <TextField
                            fullWidth
                            // required
                            label="Сумма налога"
                            variant="standard"
                            disabled={allFieldsFilled && form.action !== 'edit'}
                            type="text"
                            value={form.values.tax}
                            error={form.validation.error.tax}
                            helperText={form.validation.message.tax}
                            onChange={(e) => {
                                const value = e.target.value
                                setForm((prevForm: any) => ({
                                    ...prevForm,
                                    values: {
                                        ...prevForm.values,
                                        tax: value,
                                    },
                                }))
                            }}
                        />

                        <div className="flex gap-[20px]">
                            <Button
                                type="submit"
                                variant="contained"
                                color={
                                    salaryData?.confirm ? 'success' : 'black'
                                }
                                className={`py-[12px] px-[44px] ${
                                    salaryData?.confirm ? 'cursor-d' : ''
                                }`}
                                style={{
                                    borderRadius: '100px',
                                    width: '200px',
                                }}
                                onClick={() => {
                                    setForm({
                                        ...form,
                                        action:
                                            form.action === 'edit'
                                                ? 'edit'
                                                : 'add',
                                    })
                                }}
                                disabled={
                                    allFieldsFilled &&
                                    form.action !== 'edit' &&
                                    salaryData?.confirm === 'confirm'
                                }
                            >
                                {salaryData?.confirm === 'confirmed'
                                    ? 'Подтверждено'
                                    : 'Подтвердить'}
                            </Button>

                            {salaryData?.confirm !== 'confirmed' && (
                                <>
                                    {allFieldsFilled && (
                                        <div className="border-[1px] border-black rounded-full h-[45px] w-[45px] flex justify-center items-center cursor-pointer">
                                            <EditOutlined
                                                onClick={() => {
                                                    setForm({
                                                        ...form,
                                                        action: 'edit',
                                                    })
                                                }}
                                                className="w-[80px] h-[80px]"
                                            />
                                        </div>
                                    )}
                                </>
                            )}
                        </div>
                    </form>

                    <div className="flex">
                        <div className="flex gap-[70px] py-[20px] px-[30px] mt-[50px] border-[1px] border-[#D4D4D4] rounded-[10px]">
                            <div>
                                <h5 className="text-[14px] border-b-[2px] border-[#282828] pb-[5px] mb-1 font-semibold">
                                    Сумма от процентов
                                </h5>
                                <span className="text-[14px]">
                                    {salaryData?.percentage_amount.toFixed(0) ||
                                        0}
                                </span>
                            </div>
                            <div>
                                <h5 className="text-[14px]  border-b-[2px] border-[#282828] pb-[5px] mb-1 font-semibold">
                                    Итог
                                </h5>
                                <span className="text-[14px]">
                                    {salaryData?.total_salary.toFixed(0)}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {user === 'Администратор' && (
                <Button
                    variant="contained"
                    color="black"
                    style={{
                        borderRadius: '100px',
                        padding: '16px 30px',
                        lineHeight: 'normal',
                    }}
                    onClick={() =>
                        setServicesModal({
                            ...servicesModal,
                            show: true,
                        })
                    }
                >
                    Услуги
                </Button>
            )}

            <Tabs>
                <div className="mt-[40px]">
                    <div className="flex gap-[20px] text-white">
                        {user !== 'Администратор' && (
                            <>
                                <button
                                    className={`tab-1 ${
                                        selectedTabs.includes(1) ? 'active' : ''
                                    }`}
                                    onClick={() => handleTabClick(1)}
                                >
                                    {' '}
                                    <div className="flex flex-col gap-[10px] py-[26px] px-[30px] w-[244px] rounded-[10px] cursor-pointer">
                                        <h6 className="text-[16px]">Клиенты</h6>
                                        <span className="text-[32px]">
                                            {(
                                                Number(
                                                    analyticsCLients?.result
                                                        ?.data?.client_count,
                                                ) || 0
                                            ).toFixed(0)}
                                        </span>
                                    </div>
                                </button>
                                <button
                                    className={`tab-2 ${
                                        selectedTabs.includes(2) ? 'active' : ''
                                    }`}
                                    onClick={() => handleTabClick(2)}
                                >
                                    {' '}
                                    <div className="flex flex-col gap-[10px] py-[26px] px-[30px] w-[244px] rounded-[10px] cursor-pointer">
                                        <h6 className="text-[16px]">
                                            Проценты от продаж
                                        </h6>
                                        <span className="text-[32px]">
                                            {' '}
                                            {(
                                                Number(
                                                    analyticsManagerPrecentageSum
                                                        ?.result?.data
                                                        ?.total_percentage_sum,
                                                ) || 0
                                            ).toFixed(0)}
                                        </span>
                                    </div>
                                </button>
                                <button
                                    className={`tab-3 ${
                                        selectedTabs.includes(3) ? 'active' : ''
                                    }`}
                                    onClick={() => handleTabClick(3)}
                                >
                                    <div className="flex flex-col gap-[10px] py-[26px] px-[30px] w-[244px] rounded-[10px] cursor-pointer">
                                        <h6 className="text-[16px]">
                                            Зарплата
                                        </h6>
                                        <span className="text-[32px]">
                                            {(
                                                Number(
                                                    analyticsManagerSalary
                                                        ?.result?.data
                                                        ?.total_salary_sum,
                                                ) || 0
                                            ).toFixed(0)}
                                        </span>
                                    </div>
                                </button>
                            </>
                        )}

                        {user === 'Администратор' && (
                            <>
                                <button
                                    className={`tab-1 ${
                                        selectedTabs.includes(1) ? 'active' : ''
                                    }`}
                                    onClick={() => handleTabClick(1)}
                                >
                                    {' '}
                                    <div className="flex flex-col gap-[10px] py-[26px] w-[244px] rounded-[10px] cursor-pointer">
                                        <h6 className="text-[16px]">
                                            Доход с учетом налога
                                        </h6>
                                        <span className="text-[32px]">
                                            {analyticsClientsIncome?.result
                                                ?.data?.total_income !==
                                            undefined
                                                ? Number(
                                                      analyticsClientsIncome
                                                          .result.data
                                                          .total_income,
                                                  ).toLocaleString('ru')
                                                : 0}
                                        </span>
                                    </div>
                                </button>
                                <button
                                    className={`tab-2 ${
                                        selectedTabs.includes(2) ? 'active' : ''
                                    }`}
                                    onClick={() => handleTabClick(2)}
                                >
                                    {' '}
                                    <div className="flex flex-col gap-[10px] py-[26px] w-[244px] rounded-[10px] cursor-pointer">
                                        <h6 className="text-[16px]">Расход</h6>
                                        <span className="text-[32px]">
                                            {' '}
                                            {analyticsClientsExpense?.result
                                                ?.data?.total_expense !==
                                            undefined
                                                ? Number(
                                                      analyticsClientsExpense
                                                          .result.data
                                                          .total_expense,
                                                  ).toLocaleString('ru')
                                                : 0}
                                        </span>
                                    </div>
                                </button>
                                <button
                                    className={`tab-3 ${
                                        selectedTabs.includes(3) ? 'active' : ''
                                    }`}
                                    onClick={() => handleTabClick(3)}
                                >
                                    <div className="flex flex-col gap-[10px] py-[26px]  w-[244px] rounded-[10px] cursor-pointer">
                                        <h6 className="text-[16px]">
                                            {' '}
                                            Чистая прибыль
                                        </h6>
                                        <span className="text-[32px]">
                                            {analyticsClientsNetprofit?.result
                                                ?.data?.net_profit !== undefined
                                                ? Number(
                                                      analyticsClientsNetprofit
                                                          .result.data
                                                          .net_profit,
                                                  ).toLocaleString('ru')
                                                : 0}
                                        </span>
                                    </div>
                                </button>
                            </>
                        )}

                        <div className="flex gap-[20px]">
                            <LocalizationProvider
                                dateAdapter={AdapterDateFns}
                                adapterLocale={ru}
                            >
                                <DatePicker
                                    className="w-[150px]"
                                    value={filter.date.date_from}
                                    onChange={(newValue) => {
                                        setFilter({
                                            ...filter,
                                            date: {
                                                ...filter.date,
                                                date_from: newValue,
                                            },
                                        })
                                    }}
                                    slotProps={{
                                        textField: {
                                            variant: 'standard',
                                            required: true,
                                        },
                                    }}
                                    views={['year', 'month']}
                                    minDate={new Date('2000-01-01')}
                                    maxDate={new Date('2050-12-31')}
                                />

                                <DatePicker
                                    className="w-[170px]"
                                    value={filter.date.date_to}
                                    onChange={(newValue) => {
                                        setFilter({
                                            ...filter,
                                            date: {
                                                ...filter.date,
                                                date_to: newValue,
                                            },
                                        })
                                    }}
                                    slotProps={{
                                        textField: {
                                            variant: 'standard',
                                            required: true,
                                        },
                                    }}
                                    views={['year', 'month']}
                                    minDate={new Date('2000-01-01')}
                                    maxDate={new Date('2050-12-31')}
                                />
                            </LocalizationProvider>
                        </div>
                    </div>
                </div>

                <div className="mt-[20px]">
                    {user !== 'Администратор' && (
                        <>
                            {selectedTabs.includes(1) && analyticsCLients && (
                                <div className="w-full">
                                    <Line
                                        data={{
                                            labels:
                                                analyticsCLients?.result?.data?.data?.map(
                                                    (data: any) =>
                                                        moment(
                                                            data.label,
                                                        ).format(
                                                            analyticsCLients
                                                                ?.result?.data
                                                                .month
                                                                ? 'DD/YYYY'
                                                                : 'DD/MM/YYYY',
                                                        ),
                                                ) || [],
                                            //@ts-ignore
                                            datasets: [
                                                selectedTabs.includes(1) && {
                                                    label: 'Клиенты',
                                                    data:
                                                        analyticsCLients?.result?.data?.data?.map(
                                                            (data: any) =>
                                                                data.data,
                                                        ) || [],
                                                    fill: false,
                                                    borderColor: '#576ED0',
                                                    tension: 0.1,
                                                },
                                                selectedTabs.includes(2) && {
                                                    label: 'Проценты от продаж',
                                                    data:
                                                        analyticsManagerPrecentageSum?.result?.data?.chart_data.map(
                                                            (data: any) =>
                                                                data.data,
                                                        ) || [],
                                                    fill: false,
                                                    borderColor: '#a8c946',
                                                    tension: 0.1,
                                                },
                                                selectedTabs.includes(3) && {
                                                    label: 'Зарплата',
                                                    data:
                                                        analyticsManagerSalary?.result?.data?.chart_data?.map(
                                                            (data: any) =>
                                                                data.data,
                                                        ) || [],
                                                    fill: false,
                                                    borderColor: '#ff8768',

                                                    tension: 0.1,
                                                },
                                            ].filter(Boolean),
                                        }}
                                        options={{
                                            plugins: {
                                                legend: {
                                                    display: false,
                                                },
                                            },
                                            responsive: true,
                                            maintainAspectRatio: false,
                                            scales: {
                                                x: {
                                                    title: {
                                                        display: true,
                                                    },
                                                },
                                                y: {
                                                    title: {
                                                        display: true,
                                                    },
                                                    // min: 1200,
                                                    // max: 1080000,
                                                },
                                            },
                                        }}
                                    />
                                </div>
                            )}
                            {/* {selectedTabs.includes(2) &&
                                analyticsManagerPrecentageSum && (
                                    <div className="mt-[103px] w-full">
                                        <h1 className="text-[32px] font-semibold">
                                            Проценты от продаж
                                        </h1>
                                        <Line
                                            data={{
                                                labels:
                                                    analyticsManagerPrecentageSum?.result?.data?.chart_data.map(
                                                        (data: any) =>
                                                            moment(
                                                                data.label,
                                                            ).format(
                                                                analyticsManagerPrecentageSum
                                                                    ?.result
                                                                    ?.data.month
                                                                    ? 'DD/YYYY'
                                                                    : 'DD/MM/YYYY',
                                                            ),
                                                    ) || [],
                                                datasets: [
                                                    {
                                                        label: 'Проценты от продаж',
                                                        data:
                                                            analyticsManagerPrecentageSum?.result?.data?.chart_data.map(
                                                                (data: any) =>
                                                                    data.data,
                                                            ) || [],
                                                        fill: false,
                                                        borderColor: '#576ED0',
                                                        tension: 0.1,
                                                    },
                                                ],
                                            }}
                                            options={{
                                                scales: {
                                                    x: {
                                                        title: {
                                                            display: true,
                                                        },
                                                    },
                                                    y: {
                                                        title: {
                                                            display: true,
                                                        },
                                                    },
                                                },
                                            }}
                                        />
                                    </div>
                                )}
                            {selectedTabs.includes(3) &&
                                analyticsManagerSalary && (
                                    <div className="mt-[103px] w-full">
                                        <h1 className="text-[32px] font-semibold">
                                            Зарплата
                                        </h1>
                                        <Line
                                            data={{
                                                labels:
                                                    analyticsManagerSalary?.result?.data?.chart_data?.map(
                                                        (data: any) =>
                                                            moment(
                                                                data.label,
                                                            ).format(
                                                                analyticsManagerSalary
                                                                    ?.result
                                                                    ?.data.month
                                                                    ? 'DD/YYYY'
                                                                    : 'DD/MM/YYYY',
                                                            ),
                                                    ) || [],
                                                datasets: [
                                                    {
                                                        label: 'Зарплата',
                                                        data:
                                                            analyticsManagerSalary?.result?.data?.chart_data?.map(
                                                                (data: any) =>
                                                                    data.data,
                                                            ) || [],
                                                        fill: false,
                                                        borderColor: '#576ED0',
                                                        tension: 0.1,
                                                    },
                                                ],
                                            }}
                                            options={{
                                                scales: {
                                                    x: {
                                                        title: {
                                                            display: true,
                                                        },
                                                    },
                                                    y: {
                                                        title: {
                                                            display: true,
                                                        },
                                                    },
                                                },
                                            }}
                                        />
                                    </div>
                                )} */}
                        </>
                    )}

                    {user === 'Администратор' && (
                        <>
                            {analyticsClientsIncome && (
                                <div className="w-full">
                                    <Line
                                        data={{
                                            labels:
                                                analyticsClientsIncome?.result?.data?.chart_data?.map(
                                                    (data: any) =>
                                                        moment(
                                                            data.label,
                                                        ).format(
                                                            analyticsClientsIncome
                                                                ?.result?.data
                                                                .month
                                                                ? 'DD/YYYY'
                                                                : 'DD/MM/YYYY',
                                                        ),
                                                ) || [],
                                            //@ts-ignore
                                            datasets: [
                                                selectedTabs.includes(1) && {
                                                    label: 'Доход',
                                                    data:
                                                        analyticsClientsIncome?.result?.data?.chart_data?.map(
                                                            (data: any) =>
                                                                data.data,
                                                        ) || [],
                                                    fill: false,
                                                    borderColor: '#576ED0',
                                                    tension: 0.1,
                                                },
                                                selectedTabs.includes(2) && {
                                                    label: 'Расход',
                                                    data:
                                                        analyticsClientsExpense?.result?.data?.chart_data.map(
                                                            (data: any) =>
                                                                data.data,
                                                        ) || [],
                                                    fill: false,
                                                    borderColor: '#a8c946',
                                                    tension: 0.1,
                                                },
                                                selectedTabs.includes(3) && {
                                                    label: 'Чистая прибыль',
                                                    data:
                                                        analyticsClientsNetprofit?.result?.data?.chart_data?.map(
                                                            (data: any) =>
                                                                data.data,
                                                        ) || [],
                                                    fill: false,
                                                    borderColor: '#ff8768',
                                                    tension: 0.1,
                                                },
                                            ].filter(Boolean),
                                        }}
                                        options={{
                                            plugins: {
                                                legend: {
                                                    display: false,
                                                },
                                            },
                                            responsive: true,
                                            maintainAspectRatio: false,
                                            scales: {
                                                x: {
                                                    title: {
                                                        display: true,
                                                    },
                                                },
                                                y: {
                                                    title: {
                                                        display: true,
                                                    },
                                                },
                                            },
                                        }}
                                    />
                                </div>
                            )}

                            {/* {selectedTabs.includes(2) &&
                                analyticsClientsExpense && (
                                    <div className="mt-[103px] w-full">
                                        <h1 className="text-[32px] font-semibold">
                                            Расход
                                        </h1>
                                        <Line
                                            data={{
                                                labels:
                                                    analyticsClientsExpense?.result?.data?.chart_data.map(
                                                        (data: any) =>
                                                            moment(
                                                                data.label,
                                                            ).format(
                                                                analyticsClientsExpense
                                                                    ?.result
                                                                    ?.data.month
                                                                    ? 'DD/YYYY'
                                                                    : 'DD/MM/YYYY',
                                                            ),
                                                    ) || [],
                                                datasets: [
                                                    {
                                                        label: 'Расход',
                                                        data:
                                                            analyticsClientsExpense?.result?.data?.chart_data.map(
                                                                (data: any) =>
                                                                    data.data,
                                                            ) || [],
                                                        fill: false,
                                                        borderColor: '#576ED0',
                                                        tension: 0.1,
                                                    },
                                                ],
                                            }}
                                            options={{
                                                scales: {
                                                    x: {
                                                        title: {
                                                            display: true,
                                                        },
                                                    },
                                                    y: {
                                                        title: {
                                                            display: true,
                                                        },
                                                    },
                                                },
                                            }}
                                        />
                                    </div>
                                )} */}

                            {/* {selectedTabs.includes(3) &&
                                analyticsClientsNetprofit && (
                                    <div className="mt-[103px] w-full">
                                        <h1 className="text-[32px] font-semibold">
                                            Расход
                                        </h1>
                                        <Line
                                            data={{
                                                labels:
                                                    analyticsClientsNetprofit?.result?.data?.chart_data?.map(
                                                        (data: any) =>
                                                            moment(
                                                                data.label,
                                                            ).format(
                                                                analyticsClientsNetprofit
                                                                    ?.result
                                                                    ?.data.month
                                                                    ? 'DD/YYYY'
                                                                    : 'DD/MM/YYYY',
                                                            ),
                                                    ) || [],
                                                datasets: [
                                                    {
                                                        label: 'Чистая прибыль',
                                                        data:
                                                            analyticsClientsNetprofit?.result?.data?.chart_data?.map(
                                                                (data: any) =>
                                                                    data.data,
                                                            ) || [],
                                                        fill: false,
                                                        borderColor: '#576ED0',
                                                        tension: 0.1,
                                                    },
                                                ],
                                            }}
                                            options={{
                                                scales: {
                                                    x: {
                                                        title: {
                                                            display: true,
                                                        },
                                                    },
                                                    y: {
                                                        title: {
                                                            display: true,
                                                        },
                                                    },
                                                },
                                            }}
                                        />
                                    </div>
                                )} */}
                        </>
                    )}
                </div>
            </Tabs>

            {user === 'Администратор' && (
                <Modal
                    open={servicesModal.show}
                    onClose={() => setServicesModal(servicesModalInitialValues)}
                >
                    <div className="mainModal relative flex flex-col">
                        <IconButton
                            sx={{
                                position: 'absolute',
                                top: '10px',
                                right: '10px',
                            }}
                            onClick={() =>
                                setServicesModal(servicesModalInitialValues)
                            }
                        >
                            <CloseIcon />
                        </IconButton>

                        <h2 className="text-[30px] font-[600] text-center text-[#1E1C2A] mb-[60px]">
                            Добавление услуги
                        </h2>

                        <form
                            onSubmit={handleServicesFormSubmit}
                            className="flex items-center justify-between"
                        >
                            <TextField
                                fullWidth
                                required={true}
                                label="Название услуги"
                                variant="standard"
                                className="max-w-[288px]"
                                type="text"
                                value={servicesModal.values.title}
                                error={servicesModal.validation.error.title}
                                helperText={
                                    servicesModal.validation.message.title
                                }
                                onChange={(e) => {
                                    setServicesModal({
                                        ...servicesModal,
                                        values: {
                                            ...servicesModal.values,
                                            title: e.target.value,
                                        },
                                    })
                                }}
                            />

                            <Button
                                type="submit"
                                variant="contained"
                                className="w-[185px] inline-flex justify-center items-center font-[700]"
                                color="black"
                                style={{
                                    borderRadius: '100px',
                                    padding: '16px 30px',
                                    lineHeight: 'normal',
                                }}
                            >
                                Добавить
                            </Button>
                        </form>

                        <div className="mt-[100px]">
                            <h4 className="font-[600] text-center text-[22px] text-[#282828]">
                                Список услуг
                            </h4>

                            <div className="mt-[30px] grid grid-cols-2 gap-[24px]">
                                {!servicesList.loading &&
                                    !servicesList.error &&
                                    servicesList.result?.data.map(
                                        (item: any) => (
                                            <div className="flex items-center justify-between">
                                                {item.title}
                                                <IconButton
                                                    onClick={() => {
                                                        PaymentHistoryService.DeletePaymentHistoryService(
                                                            item.id,
                                                        )
                                                            .then(() => {
                                                                openSnackbar(
                                                                    'Услуга успешно удалена',
                                                                    {
                                                                        variant:
                                                                            'success',
                                                                    },
                                                                )
                                                                servicesList.execute()
                                                            })
                                                            .catch((err) => {
                                                                console.log(err)
                                                                openSnackbar(
                                                                    'Не удалось удалить услугу',
                                                                    {
                                                                        variant:
                                                                            'error',
                                                                    },
                                                                )
                                                            })
                                                    }}
                                                >
                                                    <DeleteOutlineOutlinedIcon />
                                                </IconButton>
                                            </div>
                                        ),
                                    )}
                            </div>
                        </div>
                    </div>
                </Modal>
            )}
        </div>
    )
}

export default Analytics
