import React, { useEffect, useState } from 'react'
import { Button, Link } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { createSearchParams, useNavigate, useParams } from 'react-router-dom'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import { ClientService } from '../service/ClientService'
import moment from 'moment'

export const ClientsView = () => {
    const navigate = useNavigate()
    const { id } = useParams()

    const [form, setForm] = useState<any>({
        id: '',
        reviews: [],
        files: [],
        full_name: '',
        contacts: '',
        address: '',
        type_of_registration: '',
        service: '',
        niche: '',
        expected_amount: '',
        date_of_payment: null,
        date_of_birth: null,
        domain_expiration_date: null,
        site: '',
        account_number: '',
        card_number: '',
        analytics: '',
        metric: '',
        goals: '',
        tm: '',
        reporting: '',
        acts: '',
        payment_invoice_number: '',
        case: '',
        comment: '',
        manager: '',
        status: '',
        requisites: '',
        reports: {
            reporting_url: '',
            period: '',
        },
    })

    useEffect(() => {
        if (id) {
            ClientService.GetClient(id).then((res: any) => {
                setForm({
                    ...form,
                    ...res.data,
                })
            })

            // .catch(() => navigate("/clients"));
        }
    }, [])

    return (
        <>
            <div className="w-full flex flex-col justify-start items-center mt-[58px]">
                <div className="w-full flex mb-[60px]">
                    <Button
                        variant="text"
                        startIcon={<ArrowBackIcon />}
                        onClick={() => navigate(-1)}
                    >
                        Назад
                    </Button>
                </div>

                <div className="w-full flex flex-col justify-start items-start gap-[40px]">
                    <div className="w-full flex justify-between items-center">
                        <h1 className="text-[40px] font-[700] text-[#282828]">
                            {form.full_name}
                        </h1>

                        <Button
                            variant="outlined"
                            sx={{
                                borderRadius: '100%',
                                padding: '10px',
                                minWidth: 'unset',
                            }}
                            color="black"
                            onClick={() =>
                                navigate({
                                    pathname: '/clients/details',
                                    search: createSearchParams({
                                        action: 'edit',
                                        ...(id && { client_id: id }),
                                    }).toString(),
                                })
                            }
                        >
                            <EditOutlinedIcon />
                        </Button>
                    </div>

                    <div className="w-full flex justify-start text-[18px] font-[600] text-[#282828]">
                        Менеджер:{' '}
                        {form.manager ? form.manager.full_name : 'отсутствует'}
                    </div>

                    <div
                        className="relative w-full rounded-[20px] py-[40px] px-[50px] grid grid-cols-2 gap-[84px]"
                        style={{ border: '1px solid #6E6E68' }}
                    >
                        <div className=" flex flex-col justify-start items-start gap-[34px]">
                            <div className="w-full flex justify-start items-start gap-[40px]">
                                <h5 className="w-[162px] text-[14px] font-[600] text-[#282828] shrink-0">
                                    Контакты
                                </h5>
                                <p className="text-[14px] font-[500] text-[#282828] w-full text-balance break-all">
                                    {form.contacts}
                                </p>
                            </div>
                            <div className="w-full flex justify-start items-start gap-[40px]">
                                <h5 className="w-[162px] text-[14px] font-[600] text-[#282828] shrink-0">
                                    Адрес
                                </h5>
                                <p className="text-[14px] font-[500] text-[#282828] w-full text-balance break-all">
                                    {form.address}
                                </p>
                            </div>
                            <div className="w-full flex justify-start items-start gap-[40px]">
                                <h5 className="w-[162px] text-[14px] font-[600] text-[#282828] shrink-0">
                                    Дата рождения
                                </h5>
                                <p className="text-[14px] font-[500] text-[#282828] w-full text-balance break-all">
                                    {form.date_of_birth
                                        ? moment(form.date_of_birth)
                                              .format('DD-MM-YYYY')
                                              .toString()
                                        : ''}
                                </p>
                            </div>
                            <div className="w-full flex justify-start items-start gap-[40px]">
                                <h5 className="w-[162px] text-[14px] font-[600] text-[#282828] shrink-0">
                                    Вид регистрации
                                </h5>
                                <p className="text-[14px] font-[500] text-[#282828] w-full text-balance break-all">
                                    {form.type_of_registration.name}
                                </p>
                            </div>

                            <div className="w-full flex justify-start items-start gap-[40px]">
                                <h5 className="w-[162px] text-[14px] font-[600] text-[#282828] shrink-0">
                                    Ниша
                                </h5>
                                <p className="text-[14px] font-[500] text-[#282828] w-full text-balance break-all">
                                    {form.niche}
                                </p>
                            </div>

                            <div className="w-full flex justify-start items-start gap-[40px]">
                                <h5 className="w-[162px] text-[14px] font-[600] text-[#282828] shrink-0">
                                    Абонплата
                                </h5>
                                <p className="text-[14px] font-[500] text-[#282828] w-full text-balance break-all">
                                    {form.subscription_fee}
                                </p>
                            </div>
                            <div className="w-full flex justify-start items-start gap-[40px]">
                                <h5 className="w-[162px] text-[14px] font-[600] text-[#282828] shrink-0">
                                    Дата оплаты
                                </h5>
                                <p className="text-[14px] font-[500] text-[#282828] w-full text-balance break-all">
                                    {form.date_of_payment
                                        ? moment(form.date_of_payment)
                                              .format('DD-MM-YYYY')
                                              .toString()
                                        : ''}
                                </p>
                            </div>
                            <div className="w-full flex justify-start items-start gap-[40px]">
                                <h5 className="w-[162px] text-[14px] font-[600] text-[#282828] shrink-0">
                                    Дата создания
                                </h5>
                                <p className="text-[14px] font-[500] text-[#282828] w-full text-balance break-all">
                                    {form.created_at
                                        ? moment(form.created_at)
                                              .format('DD-MM-YYYY')
                                              .toString()
                                        : ''}
                                </p>
                            </div>
                            <div className="w-full flex justify-start items-start gap-[40px]">
                                <h5 className="w-[162px] text-[14px] font-[600] text-[#282828] shrink-0">
                                    Сайт
                                </h5>
                                <p className="text-[14px] font-[500] text-[#282828] w-full text-balance break-all">
                                    <Link
                                        component="button"
                                        variant="body2"
                                        textAlign="start"
                                        onClick={() => {
                                            window.open(form.site, '_blank')
                                        }}
                                    >
                                        {form.site}
                                    </Link>
                                </p>
                            </div>
                            <div className="w-full flex justify-start items-start gap-[40px]">
                                <h5 className="w-[162px] text-[14px] font-[600] text-[#282828] shrink-0">
                                    Домен до
                                </h5>
                                <p className="text-[14px] font-[500] text-[#282828] w-full text-balance break-all">
                                    {form.domain_expiration_date
                                        ? moment(form.domain_expiration_date)
                                              .format('DD-MM-YYYY')
                                              .toString()
                                        : ''}
                                </p>
                            </div>
                            <div className="w-full flex justify-start items-start gap-[40px]">
                                <h5 className="w-[162px] text-[14px] font-[600] text-[#282828] shrink-0">
                                    Номер аккаунта
                                </h5>
                                <p className="text-[14px] font-[500] text-[#282828] w-full text-balance break-all">
                                    {form.account_number}
                                </p>
                            </div>
                            <div className="w-full flex justify-start items-start gap-[40px]">
                                <h5 className="w-[162px] text-[14px] font-[600] text-[#282828] shrink-0">
                                    Номер карты
                                </h5>
                                <p className="text-[14px] font-[500] text-[#282828] w-full text-balance break-all">
                                    {form.card_number}
                                </p>
                            </div>
                        </div>

                        <div className="absolute top-[40px] bottom-[40px] left-0 right-0 my-0 mx-auto rounded-[1px] w-[3px] bg-[#979CFF]"></div>

                        <div className="flex flex-col justify-start items-start gap-[34px]">
                            <div className="w-full flex justify-start items-start gap-[40px]">
                                <h5 className="w-[162px] text-[14px] font-[600] text-[#282828] shrink-0">
                                    Аналитика
                                </h5>
                                {/* <p className="text-[14px] font-[500] text-[#282828] w-full text-balance break-all">
                  {form.analytics}
                </p> */}
                                <p>{form.analytics}</p>
                            </div>
                            <div className="w-full flex justify-start items-start gap-[40px]">
                                <h5 className="w-[162px] text-[14px] font-[600] text-[#282828] shrink-0">
                                    Метрика
                                </h5>
                                {/* <p className="text-[14px] font-[500] text-[#282828] w-full text-balance break-all">
                                  {form.metric}
                                </p> */}
                                <p>{form.metric}</p>
                            </div>
                            <div className="w-full flex justify-start items-start gap-[40px]">
                                <h5 className="w-[162px] text-[14px] font-[600] text-[#282828] shrink-0">
                                    Цели
                                </h5>
                                <p className="text-[14px] font-[500] text-[#282828] w-full text-balance break-all">
                                    {form.goals}
                                </p>
                            </div>
                            <div className="w-full flex justify-start items-start gap-[40px]">
                                <h5 className="w-[162px] text-[14px] font-[600] text-[#282828] shrink-0">
                                    ТМ
                                </h5>
                                <p className="text-[14px] font-[500] text-[#282828] w-full text-balance break-all">
                                    {form.tm}
                                </p>
                            </div>
                            <div className="w-full flex justify-start items-start gap-[40px]">
                                <h5 className="w-[162px] text-[14px] font-[600] text-[#282828] shrink-0">
                                    Отчетный период
                                </h5>
                                <p className="text-[14px] font-[500] text-[#282828] w-full text-balance break-all">
                                    {form.reports?.period?.name}
                                </p>
                            </div>
                            <div className="w-full flex justify-start items-start gap-[40px]">
                                <h5 className="w-[162px] text-[14px] font-[600] text-[#282828] shrink-0">
                                    Ссылка отчета
                                </h5>
                                {/* <p className="text-[14px] font-[500] text-[#282828] w-full text-balance break-all">
                  {form.reports.reporting_url}
                </p> */}
                                <Link
                                    component="button"
                                    className="overflow-hidden max-w-full"
                                    variant="body2"
                                    textAlign="start"
                                    onClick={() => {
                                        window.open(
                                            form.reports.reporting_url,
                                            '_blank',
                                        )
                                    }}
                                >
                                    {form.reports?.reporting_url}
                                </Link>
                            </div>
                            {/* <div className='w-full flex justify-start items-start gap-[40px]'>
                                <h5 className='w-[162px] text-[14px] font-[600] text-[#282828]'>Отчетность</h5>
                                <p className='text-[14px] font-[500] text-[#282828]'></p>
                            </div> */}
                            <div className="w-full flex justify-start items-start gap-[40px]">
                                <h5 className="w-[162px] text-[14px] font-[600] text-[#282828] shrink-0">
                                    Акты
                                </h5>
                                <p className="text-[14px] font-[500] text-[#282828] w-full text-balance break-all">
                                    {form.acts}
                                </p>
                            </div>
                            <div className="w-full flex justify-start items-start gap-[40px]">
                                <h5 className="w-[162px] text-[14px] font-[600] text-[#282828] shrink-0">
                                    Счет на оплату
                                </h5>
                                <Link
                                    component="button"
                                    variant="body2"
                                    textAlign="start"
                                    onClick={() => {
                                        window.open(
                                            form.payment_invoice_number,
                                            '_blank',
                                        )
                                    }}
                                >
                                    {form.payment_invoice_number}
                                </Link>
                                {/* <p className="text-[14px] font-[500] text-[#282828] w-full text-balance break-all">
                                    {form.payment_invoice_number}
                                </p> */}
                            </div>
                            <div className="w-full flex justify-start items-start gap-[40px]">
                                <h5 className="w-[162px] text-[14px] font-[600] text-[#282828] shrink-0">
                                    Статус
                                </h5>
                                <div className="text-[14px] font-[500] text-[#282828] w-full text-balance break-all">
                                    {form.status &&
                                        (form.status.slug === 'active' ? (
                                            <Button
                                                size="small"
                                                variant="outlined"
                                                color="success"
                                                style={{
                                                    borderRadius: '100px',
                                                }}
                                            >
                                                {form.status.name}
                                            </Button>
                                        ) : (
                                            <Button
                                                size="small"
                                                variant="outlined"
                                                color="error"
                                                style={{
                                                    borderRadius: '100px',
                                                }}
                                            >
                                                {form.status.name}
                                            </Button>
                                        ))}
                                </div>
                            </div>
                            <div className="w-full flex justify-start items-start gap-[40px]">
                                <h5 className="w-[162px] text-[14px] font-[600] text-[#282828] shrink-0">
                                    Кейс
                                </h5>
                                <div className="text-[14px] font-[500] text-[#282828] w-full text-balance break-all">
                                    {form.case && (
                                        <Link
                                            component="button"
                                            variant="body2"
                                            textAlign="start"
                                            onClick={() => {
                                                window.open(form.case, '_blank')
                                            }}
                                        >
                                            {form.case.split('/').pop()}
                                        </Link>
                                    )}
                                </div>
                            </div>
                            <div className="w-full flex justify-start items-start gap-[40px]">
                                <h5 className="w-[162px] text-[14px] font-[600] text-[#282828] shrink-0">
                                    Реквизиты
                                </h5>
                                <p className="text-[14px] font-[500] text-[#282828] w-full text-balance break-all">
                                    {form.requisites}
                                </p>
                            </div>
                        </div>
                    </div>

                    <div
                        className="w-full rounded-[20px]"
                        style={{ border: '1px solid #6E6E68' }}
                    >
                        <div
                            className="w-full p-[20px]"
                            style={{ borderBottom: '1px solid #6E6E68' }}
                        >
                            <h5 className="text-[14px] font-[600] text-[#282828]">
                                Комментарий
                            </h5>
                        </div>
                        <div className="w-full p-[20px]">
                            <p className="text-[14px] font-[500] text-[#282828]">
                                {form.comment}
                            </p>
                        </div>
                    </div>

                    <div className="w-full flex col-start-1 col-end-4 justify-between items-start">
                        <div
                            className={
                                'w-[346px] flex flex-col items-start gap-[30px]'
                            }
                        >
                            <h5 className="text-[20px] font-[600] text-[#282828]">
                                Отзывы
                            </h5>
                            {form.reviews.length > 0 && (
                                <div className="w-full flex flex-col items-start gap-[20px]">
                                    {form.reviews.map(
                                        (review: any, index: number) => (
                                            <Link
                                                component="button"
                                                variant="body2"
                                                key={index}
                                                textAlign="start"
                                                onClick={() =>
                                                    window.open(
                                                        review.file,
                                                        '_blank',
                                                    )
                                                }
                                            >
                                                {review.name}
                                            </Link>
                                        ),
                                    )}
                                </div>
                            )}
                        </div>
                        <div
                            className={
                                'w-[346px] flex flex-col items-start gap-[30px]'
                            }
                        >
                            <h5 className="text-[20px] font-[600] text-[#282828]">
                                Список файлов
                            </h5>

                            {form.files.length > 0 && (
                                <div className="w-full flex flex-col items-start gap-[20px]">
                                    {form.files.map(
                                        (file: any, index: number) => {
                                            return (
                                                <Link
                                                    component="button"
                                                    variant="body2"
                                                    key={index}
                                                    textAlign="start"
                                                    onClick={() =>
                                                        window.open(
                                                            file.file,
                                                            '_blank',
                                                        )
                                                    }
                                                >
                                                    {file.name}
                                                </Link>
                                            )
                                        },
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
